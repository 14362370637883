/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansSemiBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.Montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}
.MontserratSemiBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
.MontserratBold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
}
form {
  max-width: 100vw;
  overflow: hidden;
  position: relative;
}
html {
  font-size: 20px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.textContent {
  font-size: 1rem;
}
@media (max-width: 767px) {
  .textContent {
    font-size: 0.8rem;
    text-align: center;
  }
}
.textContent h1,
.lower-content h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0b44a5;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
.outer-wrapper {
  overflow-x: hidden;
  max-width: 100vw;
}
.fade-out {
  width: 20px;
  height: 5px;
  background: #bfbfbf;
  margin: 40px auto;
  background-position: center;
}
.container {
  position: relative;
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
    margin: 0 auto 0;
  }
}
.header {
  padding: 30px 0;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .header {
    padding-top: 6.84%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    position: relative;
    display: block;
    margin: 50px auto 0;
    max-width: 400px !important;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    left: 0.5%;
    bottom: 50%;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    width: 26%;
    text-align: left;
  }
}
.header__follow {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  color: #b3b3b3;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .header__follow {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__follow {
    position: absolute;
    width: 15.47%;
    bottom: 41.25%;
    left: 36.75%;
  }
}
@media (max-width: 767px) {
  .header__follow {
    margin-top: 45px;
  }
}
@media (min-width: 768px) {
  .header__follow {
    font-size: 1.45vw;
  }
}
@media (min-width: 1330px) {
  .header__follow {
    font-size: 0.98rem;
  }
}
.header__enquiries {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  font-weight: 700;
  color: #b3b3b3;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .header__enquiries {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__enquiries {
    position: absolute;
    width: 8.63%;
    bottom: 41.25%;
    left: 65.3%;
  }
}
@media (max-width: 767px) {
  .header__enquiries {
    margin-top: 45px;
  }
}
@media (min-width: 768px) {
  .header__enquiries {
    font-size: 1.45vw;
  }
}
@media (min-width: 1330px) {
  .header__enquiries {
    font-size: 0.98rem;
  }
}
.header__email {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  color: #0090ff;
  font-size: 1.2rem;
  line-height: 1;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .header__email {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    width: 23.68%;
    bottom: 41.25%;
    left: 74.96%;
  }
}
@media (max-width: 420px) {
  .header__email {
    font-size: 0.8rem;
  }
}
@media (min-width: 768px) {
  .header__email {
    font-size: 1.45vw;
  }
}
@media (min-width: 1330px) {
  .header__email {
    font-size: 0.98rem;
  }
}
.header__email:hover {
  color: #1dbbd3;
}
@media (max-width: 767px) {
  .header__social-links {
    margin: 10px auto 0;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    bottom: 33%;
    left: 54.6%;
    width: 10%;
    text-align: center;
  }
}
.header__social-link {
  display: inline-block;
  width: 30%;
  margin: 0 0.5%;
}
@media (max-width: 767px) {
  .header__social-link {
    width: 45px;
    margin: 0 5px;
  }
}
.toplinks__wrapper {
  background: #f2faff;
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  position: relative;
}
@media (max-width: 767px) {
  .toplinks__wrapper {
    display: none;
  }
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__link {
  color: #0f6bb3;
  background: none;
  font-size: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 20px 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  display: block;
  position: relative;
  z-index: 1;
}
.toplinks__link.open .dropdown-toggle:focus {
  color: #FFF;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #FFF !important;
  background-color: transparent;
}
.toplinks__link:hover:before,
.toplinks__link.active:before,
.toplinks__link:focus:before {
  opacity: 1;
}
.toplinks__link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #24ceff;
  background: -webkit-gradient(linear, left top, left bottom, from(#24ceff), to(#0fb9ff));
  background: linear-gradient(to bottom, #24ceff, #0fb9ff);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}
.toplinks__dropdown {
  background: #f2faff;
  text-align: center;
  padding: 0;
  min-width: 100%;
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  padding: 5px 10px !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  font-size: 0.8rem;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  color: #0f6bb3 !important;
  position: relative;
  transition: all 0.3s;
  z-index: 1;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  color: #fff !important;
}
.toplinks__dropdown-link:hover:before,
.toplinks__dropdown-link:focus:before,
.toplinks__dropdown-link.active:before {
  opacity: 1;
}
.toplinks__dropdown-link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #24ceff;
  background: -webkit-gradient(linear, left top, left bottom, from(#24ceff), to(#0fb9ff));
  background: linear-gradient(to bottom, #24ceff, #0fb9ff);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}
.toplinks__search {
  margin: 20px auto;
}
@media (min-width: 1330px) {
  .toplinks__search {
    margin: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
.toplinks__search input {
  border-radius: 24px;
  border: none;
  height: 40px;
  padding-left: 15px;
  width: 100%;
  text-align: center;
}
@media (min-width: 1330px) {
  .toplinks__search input {
    max-width: 210px;
    width: auto;
    text-align: left;
  }
}
.banner {
  margin: 0 auto;
  max-width: 1950px;
  max-height: 680px;
  overflow: hidden;
}
.banner #CarouselContainer {
  padding-top: 42.46%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner #CarouselContainer {
  max-width: none !important;
  max-height: none !important;
}
#HomepageWelcome h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}
#HomepageWelcome h1 span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
}
.book-online {
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
  .book-online {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.book-online__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .book-online__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.book-online__inner > div:last-child > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .book-online__inner > div:last-child > div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.book-online__wrapper {
  background-color: #23cdff;
  background: -webkit-gradient(linear, left top, left bottom, from(#23cdff), to(#10baff));
  background: linear-gradient(to bottom, #23cdff, #10baff);
}
@media (min-width: 768px) {
  .book-online__wrapper {
    padding: 30px 0;
  }
}
@media (min-width: 992px) {
  .book-online__wrapper {
    padding: 40px 0;
  }
}
.book-online__text {
  color: #fff;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1;
  margin-right: 2%;
  font-size: 2rem;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .book-online__text {
    margin-bottom: 0;
    font-size: 1.8rem;
  }
}
@media (min-width: 1200px) {
  .book-online__text {
    font-size: 2rem;
  }
}
@media (min-width: 1330px) {
  .book-online__text {
    font-size: 2.5rem;
  }
}
.book-online .PostcodeMessage {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: auto;
  margin: 0;
  padding: 10px;
  -webkit-transform: translateY(-130%);
          transform: translateY(-130%);
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory {
  height: 45px;
  border-radius: 5px;
  border: none;
  padding-left: 2%;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  padding-right: 35px;
  width: 230px;
  margin: 0 4px;
}
@media (max-width: 767px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    width: 100%;
    min-width: 230px;
    max-width: 400px;
    margin: 0 auto 10px;
  }
}
@media (min-width: 768px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    width: 180px;
  }
}
@media (min-width: 992px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    width: 185px;
  }
}
@media (min-width: 1200px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    width: 230px;
  }
}
@media (min-width: 1330px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    height: 50px;
  }
}
.book-online #PostcodeSearchLeft select:focus,
.book-online #PostcodeSearchLeft input:focus,
.book-online .SearchDate:focus,
.book-online .SearchCategory:focus {
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 4px #02aeee;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 4px #02aeee;
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/012/icon-map-marker.svg");
}
.book-online .SearchDate {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/012/icon-calendar.svg");
}
.book-online .SearchCategory {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/012/icon-smile-o.svg");
  background-position: right 30px center;
  padding-right: 55px;
}
.book-online .SearchButton {
  width: 15.66%;
  left: 83.69%;
  border-radius: 5px;
  color: #fff;
  height: 45px;
  width: 170px;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: transparent;
  border: 3px solid #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 767px) {
  .book-online .SearchButton {
    margin: 20px auto 0;
  }
}
@media (min-width: 768px) {
  .book-online .SearchButton {
    width: 130px;
  }
}
@media (min-width: 992px) {
  .book-online .SearchButton {
    width: 140px;
  }
}
@media (min-width: 1200px) {
  .book-online .SearchButton {
    width: 170px;
  }
}
@media (min-width: 1330px) {
  .book-online .SearchButton {
    height: 50px;
  }
}
.book-online .SearchButton:hover {
  background: #fff;
  color: #02aeee;
}
.maincontent {
  padding: 50px 0;
}
@media (max-width: 767px) {
  .maincontent__right {
    padding: 0;
  }
}
.mainTitle {
  color: #000;
}
.category-links {
  margin-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 1329px) {
  .category-links {
    margin-top: 21%;
  }
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  z-index: 1;
  overflow: hidden;
  margin: 10px 0;
  padding: 15px;
  text-align: left;
  line-height: 100%;
  color: #4065d0;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  font-size: 1rem;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  background-color: #23ccff;
  background: -webkit-gradient(linear, left top, left bottom, from(#23ccff), to(#11bcff));
  background: linear-gradient(to bottom, #23ccff, #11bcff);
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -2;
}
.category-links__link:after,
.category-links__child-link:after {
  content: "";
  background-color: #FFF;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (min-width: 1330px) {
  .category-links__link,
  .category-links__child-link {
    font-size: 20px;
  }
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  color: #fff;
}
.category-links__link:hover:after,
.category-links__child-link:hover:after,
.category-links__link:focus:after,
.category-links__child-link:focus:after,
.category-links__link.active:after,
.category-links__child-link.active:after {
  opacity: 0;
}
.category-links__child-link {
  margin-left: 10px;
}
.newsletter {
  background: #1c68d3;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/012/newsletter-bg.png");
  background-size: 100% 100%;
  padding: 50px 0 75px;
  text-align: center;
}
@media (max-width: 767px) {
  .newsletter {
    display: none;
  }
}
.newsletter__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  font-size: 2rem;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
}
.newsletter__subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  color: #FFF;
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 30px;
}
.newsletter__name {
  padding: 10px;
  border-radius: 5px;
  height: 45px;
  padding-left: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  width: 300px;
  margin: 0 5px;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 2px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (min-width: 1330px) {
  .newsletter__name {
    height: 60px;
  }
}
.newsletter__name:focus {
  border: 2px solid #16c0ff;
}
.newsletter__email {
  padding: 10px;
  width: 350px;
  border-radius: 5px;
  height: 45px;
  padding-left: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  margin: 0 5px;
  border: 2px solid transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (min-width: 1330px) {
  .newsletter__email {
    height: 60px;
  }
}
.newsletter__email:focus {
  border: 2px solid #16c0ff;
}
.newsletter__submit {
  background-color: #14a430;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  border-radius: 5px;
  text-transform: uppercase;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  height: 45px;
  width: 160px;
  font-size: 0.7rem;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 5px;
}
.newsletter__submit:hover {
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0);
  color: #fff;
}
@media (min-width: 1330px) {
  .newsletter__submit {
    height: 60px;
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1329px) {
  .newsletter__submit {
    position: relative;
    left: 0;
  }
}
.newsletter #NewsletterControl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.newsletter #NewsletterControl p,
.newsletter #NewsletterControl .clear {
  display: none;
}
.social-intro {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 2vw;
  padding: 0 10%;
  margin-bottom: 20px;
}
@media (min-width: 1330px) {
  .social-intro {
    font-size: 25px;
  }
}
.home-assets {
  padding: 30px 0 10%;
}
.home-assets__wrapper {
  background-color: #FFF;
  position: relative;
}
.home-assets__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  font-size: 40px;
}
.testimonials {
  text-align: center;
  position: relative;
  overflow: hidden;
}
.testimonials__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  font-size: 40px;
  margin-bottom: 50px;
  text-transform: uppercase;
}
.testimonials__wrapper {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
}
.testimonials__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  margin: 0 6%;
  padding: 30px 15px;
  border-radius: 5px;
}
.testimonials .slick-slide {
  opacity: 0.7;
  margin: 0 -27px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: all 1s;
  transition: all 1s;
}
.testimonials .slick-slide.slick-center {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.testimonials__text {
  color: #808080;
  margin-bottom: 20px;
  font-size: 1.1rem;
}
@media (max-width: 767px) {
  .testimonials__text {
    font-size: 0.8rem;
  }
}
.testimonials__author {
  color: #5a1f76;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
@media (max-width: 767px) {
  .testimonials__author {
    font-size: 0.8rem;
  }
}
.castlePanel {
  width: 100%;
  border: 2px solid #e6e6e6;
  padding: 0 0 15px;
  border-radius: 5px;
  margin: 0 0 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 767px) {
  .castlePanel {
    text-align: center;
  }
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel .SingleImagePanel {
  width: 90%;
  left: 5%;
  background: transparent;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 10px 0 20px;
  position: relative;
  height: auto;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  width: 90%;
  padding-top: 75%;
  display: block;
  left: 5%;
}
.castlePanel .SingleImagePanel img {
  top: 0 !important;
}
.castlePanel .castleTitlePanel {
  margin-top: 10px;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.castlePanel .castleTitlePanel a {
  color: #404040;
  font-size: 0.7rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  position: relative;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #404040;
  font-size: 1rem;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  padding: 0 10px;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.7rem;
  color: #df1f1f;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 0.9rem;
}
.castlePanel .castlePriceDefault {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.castlePanel .castleCheckBook {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  width: 90%;
  left: 5%;
  padding: 15px;
  margin-top: 10px;
  position: relative;
  height: auto;
  font-size: 0.8rem;
  background: #1dc8ff;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  text-transform: uppercase;
}
#PageListings,
#HomePageListings {
  padding: 0 10px;
}
@media (max-width: 767px) {
  #PageListings,
  #HomePageListings {
    padding: 0 15px;
  }
}
#PageListings > div,
#HomePageListings > div {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
@media (max-width: 420px) {
  #PageListings > div,
  #HomePageListings > div {
    width: 100%;
  }
}
.productsintro {
  padding-left: 5%;
  position: relative;
  text-transform: none;
  font-size: 3.5vw;
  text-align: center;
}
@media (min-width: 992px) {
  .productsintro {
    font-size: 25px;
    text-align: left;
  }
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #19c3ff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  .bookButtons {
    max-width: 198px !important;
  }
}
@media (max-width: 767px) {
  #HomepageGalleryWrapper {
    display: none;
  }
}
#HomepageGalleryWrapper .container {
  background: black;
  border-top: 15px solid #fff;
}
@media (min-width: 768px) {
  #HomepageGalleryWrapper .container {
    padding: 35px 10px 15px;
  }
}
@media (min-width: 1330px) {
  #HomepageGalleryWrapper .container {
    padding: 10px 15px;
  }
}
@media (max-width: 767px) {
  .home-gallery {
    display: none;
  }
}
@media (min-width: 768px) {
  .home-gallery {
    display: block;
    position: relative;
    height: 280px;
    margin: 10px auto;
    max-width: 1900px;
  }
  .home-gallery__outer {
    top: 0;
    bottom: 0;
    left: 30px;
    right: 30px;
    overflow: hidden;
    position: absolute;
  }
  .home-gallery__inner {
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: -280px;
    right: -280px;
  }
  .home-gallery__inner div {
    float: left;
    width: 280px;
    height: 280px;
  }
  .home-gallery__inner div a {
    display: block;
    border-radius: 5px;
    overflow: hidden;
    width: 260px;
    height: 260px;
    padding: 0;
    margin: 0 auto;
    border: 2px solid #ababab;
    background-color: black;
  }
  .home-gallery__inner div a img {
    top: 0;
    left: 0 !important;
  }
  .home-gallery__inner div a:hover {
    border-color: #4764b9;
  }
}
.clients {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
@media (max-width: 767px) {
  .clients {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.clients__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  font-size: 2rem;
  color: #000;
  text-transform: uppercase;
  margin: 0;
}
@media (max-width: 767px) {
  .clients__title {
    font-size: 1.4rem;
  }
}
.clients__gallery {
  width: 22%;
  position: relative;
}
@media (max-width: 767px) {
  .clients__gallery {
    width: 48%;
  }
}
.clients .carousel-inner {
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.clients__column {
  margin-top: 6%;
}
.clients .item {
  width: 100% !important;
  height: 100% !important;
}
.clients__image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-top: 100%;
}
.clients__image-wrapper img {
  -ms-flex-item-align: center;
      align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
#FooterWrapper {
  background-color: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  #FooterWrapper {
    text-align: center;
  }
}
#FooterWrapper .container {
  background-color: transparent;
  padding: 0 15px;
}
@media (max-width: 767px) {
  #FooterWrapper .container {
    position: relative;
    margin-top: 0;
  }
}
#FooterWrapper .footerlinks {
  text-align: center;
}
@media (max-width: 767px) {
  #FooterWrapper .footerlinks {
    text-align: center;
    margin-top: 15px;
  }
}
#FooterWrapper .footerlinks a {
  color: white;
  margin-right: 10px;
}
#FooterWrapper #BCNLink {
  margin: 25px auto;
  display: block;
  max-width: 180px;
}
@media (min-width: 768px) {
  #FooterWrapper #BCNLink {
    float: right;
  }
}
#FooterWrapper #BCNLink img {
  margin: 0 auto;
}
a.BackToCategory {
  background-color: #68C6D3;
  text-shadow: none;
}
a.BackToCategory:hover {
  background-color: #4A67BC;
  text-shadow: none;
}
.DetailsLeft {
  background-image: none;
}
#BookingOnlineLink {
  color: white;
  text-align: right;
}
#BookingOnlineLink:hover {
  color: white;
}
@media (max-width: 767px) {
  #BookingOnlineLink {
    text-align: center;
  }
}
img.HoverBounce,
.HoverBounce img {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  display: block;
  overflow: hidden;
}
img.HoverBounce:hover,
.HoverBounce img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  z-index: 100;
}
a.HoverBounce {
  display: block;
}
a.HoverBounce img {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  display: block;
  overflow: hidden;
}
a.HoverBounce :hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  z-index: 100;
}
.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}
/*ANIMATIONS*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
