@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1329px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #0b44a5;
@castleDetailsColor: #19c3ff;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/012/";


.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansSemiBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600 !important;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700 !important;
}

.Montserrat {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400 !important;
}

.MontserratSemiBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600 !important;
}

.MontserratBold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700 !important;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700 !important;
}

form {
	max-width: 100vw;
	overflow: hidden;
	position: relative;
}

html {
	font-size: 20px;
}

body {
	.BodyFont();
}

.nicefont, h1, h2, h3 {
}

.textContent {
	font-size: 1rem;

	@media @mobile {
		font-size: 0.8rem;
		text-align: center;
	}
}

.textContent h1, .lower-content h2 {
	.MontserratSemiBold;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}

.outer-wrapper {
	overflow-x: hidden;
	max-width: 100vw;
}

.fade-out {
	width: 20px;
	height: 5px;
	background: #bfbfbf;
	margin: 40px auto;
	background-position: center;
}

.container {
	position: relative;

	@media @tablet {
		width: 100%;
		padding: 0 20px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1300px;
		margin: 0 auto 0;
	}
}

.header {
	padding: 30px 0;
	text-align: center;
	@width: 1170px;
	@height: 80px;
	position: relative;

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__logo {
		@media @mobile {
			position: relative;
			display: block;
			margin: 50px auto 0;
			max-width: 400px !important;
		}

		@media @tablet {
			position: absolute;
			left: 0.5%;
			bottom: 50%;
			transform: translateY(50%);
			width: 26%;
			text-align: left;
		}
	}

	&__follow {
		.TopBarItem(181,@width,@height,auto,33,left,430);
		.MontserratBold;
		color: #b3b3b3;
		font-size: 1.2rem;
		text-transform: uppercase;
		line-height: 1;
		white-space: nowrap;

		@media @mobile {
			margin-top: 45px;
		}

		@media @tablet {
			font-size: 1.45vw;
		}

		@media @desktop {
			font-size: 0.98rem;
		}
	}

	&__enquiries {
		.TopBarItem(101,@width,@height,auto,33,left,764);
		.Montserrat;
		font-weight: 700;
		color: #b3b3b3;
		font-size: 1.2rem;
		text-transform: uppercase;
		line-height: 1;
		white-space: nowrap;

		@media @mobile {
			margin-top: 45px;
		}

		@media @tablet {
			font-size: 1.45vw;
		}

		@media @desktop {
			font-size: 0.98rem;
		}
	}

	&__email {
		.TopBarItem(277,@width,@height,auto,33,left,877);
		.MontserratSemiBold;
		color: #0090ff;
		font-size: 1.2rem;
		line-height: 1;
		white-space: nowrap;

		@media (max-width:420px) {
			font-size: 0.8rem;
		}

		@media @tablet {
			font-size: 1.45vw;
		}

		@media @desktop {
			font-size: 0.98rem;
		}

		&:hover {
			color: #1dbbd3;
		}
	}

	&__social-links {
		@media @mobile {
			margin: 10px auto 0;
		}

		@media @tablet {
			position: absolute;
			bottom: 33%;
			left: 54.6%;
			width: 10%;
			text-align: center;
		}
	}

	&__social-link {
		display: inline-block;
		width: 30%;
		margin: 0 0.5%;

		@media @mobile {
			width: 45px;
			margin: 0 5px;
		}
	}
}

.toplinks {
	&__wrapper {
		background: #f2faff;
		box-shadow: 0px 3px 5px rgba(0,0,0,0.3);
		position: relative;

		@media @mobile {
			display: none;
		}
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;
	}

	&__link {
		color: #0f6bb3;
		background: none;
		font-size: 14px;
		.transition(all 0.3s);
		padding: 20px 5px;
		font-size: 0.8rem;
		white-space: nowrap;
		.Montserrat;
		display: block;
		position: relative;
		z-index: 1;

		&.open .dropdown-toggle:focus {
			color: #FFF;
		}

		&:hover, &.active, &:focus {
			color: #FFF !important;
			background-color: transparent;

			&:before {
				opacity: 1;
			}
		}

		&:before {
			.StickToEdges;
			.Gradient(#24ceff,#0fb9ff);
			opacity: 0;
			transition: all 0.3s;
			z-index: -1;
		}
	}

	&__dropdown {
		background: #f2faff;
		text-align: center;
		padding: 0;
		min-width: 100%;
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		padding: 5px 10px !important;
		.Montserrat;
		font-size: 0.8rem;
		.Transition3s;
		color: #0f6bb3 !important;
		position: relative;
		transition: all 0.3s;
		z-index: 1;

		&:hover, &:focus, &.active {
			color: #fff !important;

			&:before {
				opacity: 1;
			}
		}

		&:before {
			.StickToEdges;
			.Gradient(#24ceff,#0fb9ff);
			opacity: 0;
			transition: all 0.3s;
			z-index: -1;
		}
	}

	&__search {
		margin: 20px auto;

		@media @desktop {
			margin: 0;
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
		}

		input {
			border-radius: 24px;
			border: none;
			height: 40px;
			padding-left: 15px;
			width: 100%;
			text-align: center;

			@media @desktop {
				max-width: 210px;
				width: auto;
				text-align: left;
			}
		}
	}
}

.banner {
	margin: 0 auto;
	max-width: 1950px;
	max-height: 680px;
	overflow: hidden;
	.BannerPanelSet(42.46%);

	#CarouselContainer {
		max-width: none !important;
		max-height: none !important;
	}
}

#HomepageWelcome h1 {
	.Montserrat;

	span {
		.MontserratBold;
	}
}

.book-online {
	z-index: 3;
	display: flex;
	justify-content: center;
	position: relative;
	flex-direction: column;

	@media @md {
		flex-direction: row;
	}

	&__inner {
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;

		@media @sm {
			flex-direction: row;
		}

		& > div:last-child {
			& > div {
				display: flex;
				flex-direction: column;

				@media @sm {
					flex-direction: row;
				}
			}
		}
	}

	&__wrapper {
		.Gradient(#23cdff,#10baff);

		@media @sm {
			padding: 30px 0;
		}

		@media @md {
			padding: 40px 0;
		}
	}

	&__text {
		color: #fff;
		.PTSansNarrowBold;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 0;
		line-height: 1;
		margin-right: 2%;
		font-size: 2rem;
		margin-bottom: 20px;

		@media @md {
			margin-bottom: 0;
			font-size: 1.8rem;
		}

		@media @lg {
			font-size: 2rem;
		}

		@media @desktop {
			font-size: 2.5rem;
		}
	}

	.PostcodeMessage {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: auto;
		margin: 0;
		padding: 10px;
		transform: translateY(-130%);
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory {
		height: 45px;
		border-radius: 5px;
		border: none;
		padding-left: 2%;
		background-color: #fff;
		border: 1px solid #e6e6e6;
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 20px;
		padding-right: 35px;
		width: 230px;
		margin: 0 4px;

		@media @xs {
			width: 100%;
			min-width: 230px;
			max-width: 400px;
			margin: 0 auto 10px;
		}

		@media @sm {
			width: 180px;
		}

		@media @md {
			width: 185px;
		}

		@media @lg {
			width: 230px;
		}

		@media @desktop {
			height: 50px;
		}

		&:focus {
			outline: 0;
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 4px #02aeee;
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input {
		background-image: url("@{cloudUrl}icon-map-marker.svg");
	}

	.SearchDate {
		background-image: url("@{cloudUrl}icon-calendar.svg");
	}

	.SearchCategory {
		background-image: url("@{cloudUrl}icon-smile-o.svg");
		background-position: right 30px center;
		padding-right: 55px;
	}

	.SearchButton {
		width: 15.66%;
		left: 83.69%;
		border-radius: 5px;
		color: #fff;
		height: 45px;
		width: 170px;
		.FlexCenter;
		background: transparent;
		border: 3px solid #fff;
		.MontserratBold;
		font-size: 1rem;
		text-transform: uppercase;
		margin: 0 4px;
		transition: all 0.3s;

		@media @xs {
			margin: 20px auto 0;
		}

		@media @sm {
			width: 130px;
		}

		@media @md {
			width: 140px;
		}

		@media @lg {
			width: 170px;
		}

		@media @desktop {
			height: 50px;
		}

		&:hover {
			background: #fff;
			color: #02aeee;
		}
	}
}

.maincontent {
	padding: 50px 0;

	&__right {
		@media @mobile {
			padding: 0;
		}
	}
}

.mainTitle {
	color: #000;
}

.category-links {
	margin-bottom: 10px;

	@media @tablet-only {
		margin-top: 21%;
	}

	&__link, &__child-link {
		position: relative;
		display: block;
		z-index: 1;
		overflow: hidden;
		margin: 10px 0;
		padding: 15px;
		text-align: left;
		line-height: 100%;
		color: #4065d0;
		.PTSansNarrowBold;
		font-size: 1rem;

		&:before {
			content: "";
			.Gradient(#23ccff,#11bcff);
			border-radius: 5px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: all 0.3s;
			z-index: -2;
		}

		&:after {
			content: "";
			background-color: #FFF;
			border: 2px solid #e8e8e8;
			border-radius: 5px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			transition: all 0.3s;
		}

		@media @desktop {
			font-size: 20px;
		}

		&:hover,&:focus, &.active {
			color: #fff;

			&:after {
				opacity: 0;
			}
		}
	}

	&__child-link{
		margin-left:10px;
	}
}

.newsletter {
	background: #1c68d3;
	background-image: url("@{cloudUrl}newsletter-bg.png");
	background-size: 100% 100%;
	padding: 50px 0 75px;
	text-align: center;

	@media @mobile {
		display: none;
	}

	&__title {
		.Montserrat;
		font-size: 2rem;
		color: #FFF;
		text-align: center;
		text-transform: uppercase;
	}

	&__subtitle {
		.Montserrat;
		color: #FFF;
		font-size: 1.3rem;
		text-align: center;
		margin-bottom: 30px;
	}

	&__name {
		padding: 10px;
		border-radius: 5px;
		height: 45px;
		padding-left: 10px;
		.OpenSansSemiBold;
		width: 300px;
		margin: 0 5px;
		border: none;
		box-shadow: none;
		border: 2px solid transparent;
		transition: all 0.3s;

		@media @desktop {
			height: 60px;
		}

		&:focus {
			border: 2px solid #16c0ff;
		}
	}

	&__email {
		padding: 10px;
		width: 350px;
		border-radius: 5px;
		height: 45px;
		padding-left: 10px;
		.OpenSansSemiBold;
		margin: 0 5px;
		border: 2px solid transparent;
		box-shadow: none;
		transition: all 0.3s;

		@media @desktop {
			height: 60px;
		}

		&:focus {
			border: 2px solid #16c0ff;
		}
	}

	&__submit {
		background-color: #14a430;
		color: #fff;
		.MontserratBold;
		border-radius: 5px;
		text-transform: uppercase;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
		height: 45px;
		width: 160px;
		font-size: 0.7rem;
		.FlexCenter;
		transition: all 0.3s;
		margin: 0 5px;

		&:hover {
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.0);
			color: #fff;
		}

		@media @desktop {
			height: 60px;
			font-size: 1rem;
		}

		@media @tablet-only {
			position: relative;
			left: 0;
		}
	}

	#NewsletterControl {
		display: flex;
		justify-content: center;

		p, .clear {
			display: none;
		}
	}
}

.social-intro {
	.MontserratBold;
	font-size: 2vw;
	padding: 0 10%;
	margin-bottom: 20px;

	@media @desktop {
		font-size: 25px;
	}
}

.home-assets {
	padding: 30px 0 10%;

	&__wrapper {
		background-color: #FFF;
		position: relative;
	}

	&__title {
		.MontserratBold;
		color: #000;
		text-align: center;
		text-transform: uppercase;
		font-size: 40px;
	}
}

.testimonials {
	text-align: center;
	position: relative;
	overflow: hidden;

	&__title {
		.MontserratBold;
		font-weight: 600;
		color: #FFF;
		text-align: center;
		font-size: 40px;
		margin-bottom: 50px;
		text-transform: uppercase;
	}

	&__wrapper {
		position: relative;
		padding-top: 70px;
		padding-bottom: 70px;
	}

	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__link {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		background-color: #fff;
		margin: 0 6%;
		padding: 30px 15px;
		border-radius: 5px;
	}

	.slick-slide {
		opacity: 0.7;
		margin: 0 -27px;
		transform: scale(0.9);
		transition: all 1s;

		&.slick-center {
			opacity: 1;
			transform: scale(1);
		}
	}

	&__text {
		color: #808080;
		margin-bottom: 20px;
		font-size: 1.1rem;

		@media @mobile {
			font-size: 0.8rem;
		}
	}

	&__author {
		color: #5a1f76;
		padding: 10px;
		margin-bottom: 10px;
		font-size: 1.1rem;
		.OpenSansBold;

		@media @mobile {
			font-size: 0.8rem;
		}
	}
}

.castlePanel {
	width: 100%;
	border: 2px solid #e6e6e6;
	padding: 0 0 15px;
	border-radius: 5px;
	margin: 0 0 30px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
	display: flex;
	flex-direction: column;

	@media @mobile {
		text-align: center;
	}

	&:hover {
		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
	}

	.SingleImagePanel {
		width: 90%;
		left: 5%;
		background: transparent;
		order: 1;
		margin: 10px 0 20px;
		position: relative;
		height: auto;
		padding-top: 0;

		&:before {
			content: "";
			width: 90%;
			padding-top: 75%;
			display: block;
			left: 5%;
		}

		img {
			top: 0 !important;
		}
	}

	.castleTitlePanel {
		margin-top: 10px;
		padding: 0 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		order: 2;

		a {
			color: #404040;
			font-size: 0.7rem;
			.Montserrat;
			position: relative;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #404040;
		font-size: 1rem;
		order: 3;
		padding: 0 10px;
		margin-top: 10px;
		.Montserrat;

		span.oldprice {
			font-size: 0.7rem;
			color: #df1f1f;
		}

		span.newprice {
			font-size: 0.9rem;
		}
	}

	.castlePriceDefault {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	.castleCheckBook {
		order: 4;
		width: 90%;
		left: 5%;
		padding: 15px;
		margin-top: 10px;
		position: relative;
		height: auto;
		font-size: 0.8rem;
		background: #1dc8ff;
		border-radius: 8px;
		.MontserratBold;
		text-transform: uppercase;
	}
}

#PageListings, #HomePageListings {
	padding: 0 10px;

	@media @mobile {
		padding: 0 15px;
	}

	& > div {
		padding-left: 10px !important;
		padding-right: 10px !important;

		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.productsintro {
	padding-left: 5%;
	position: relative;
	text-transform: none;
	font-size: 3.5vw;
	text-align: center;

	@media @md {
		font-size: 25px;
		text-align: left;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	.MontserratSemiBold;
}

.bookButtons {
	@media @mobile {
		max-width: 198px !important;
	}
}

#HomepageGalleryWrapper {
	@media @mobile {
		display: none;
	}

	.container {
		background: black;
		border-top: 15px solid #fff;

		@media @tablet {
			padding: 35px 10px 15px;
		}

		@media @desktop {
			padding: 10px 15px;
		}
	}
}

.home-gallery {
	@media @mobile {
		display: none;
	}

	@media @tablet {
		display: block;
		position: relative;
		height: 280px;
		margin: 10px auto;
		max-width: 1900px;

		&__outer {
			top: 0;
			bottom: 0;
			left: 30px;
			right: 30px;
			overflow: hidden;
			position: absolute;
		}

		&__inner {
			position: absolute;
			overflow: hidden;
			top: 0;
			bottom: 0;
			left: -280px;
			right: -280px;

			div {
				float: left;
				width: 280px;
				height: 280px;

				a {
					display: block;
					border-radius: 5px;
					overflow: hidden;
					width: 260px;
					height: 260px;
					padding: 0;
					margin: 0 auto;
					border: 2px solid #ababab;
					background-color: black;

					img {
						top: 0;
						left: 0 !important;
					}

					&:hover {
						border-color: #4764b9;
					}
				}
			}
		}
	}
}

.clients {
	position: relative;
	display: flex;
	justify-content: space-around;

	@media @mobile {
		flex-wrap: wrap;
	}

	&__title {
		.MontserratBold;
		font-size: 2rem;
		color: #000;
		text-transform: uppercase;
		margin: 0;

		@media @mobile {
			font-size: 1.4rem;
		}
	}

	&__gallery {
		width: 22%;
		position: relative;

		@media @mobile {
			width: 48%;
		}
	}

	.carousel-inner {
		.FlexCenter;
	}

	&__column {
		margin-top: 6%;
	}

	.item {
		width: 100% !important;
		height: 100% !important;
	}

	&__image-wrapper {
		display: flex;
		height: 100%;
		padding-top: 100%;

		img {
			align-self: center;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}
}

#FooterWrapper {
	background-color: #000;
	color: #fff;
	padding: 25px 10px;
	width: 100%;
	z-index: 1;
	position: relative;

	@media @mobile {
		text-align: center;
	}

	.container {
		background-color: transparent;
		padding: 0 15px;

		@media @mobile {
			position: relative;
			margin-top: 0;
		}
	}

	.footerlinks {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}

		a {
			color: white;
			margin-right: 10px;
		}
	}

	#BCNLink {
		margin: 25px auto;
		display: block;
		max-width: 180px;

		@media @sm {
			float: right;
		}

		img {
			margin: 0 auto;
		}
	}
}

a.BackToCategory {
	background-color: #68C6D3;
	text-shadow: none;

	&:hover {
		background-color: #4A67BC;
		text-shadow: none;
	}
}

.DetailsLeft {
	background-image: none;
}

#BookingOnlineLink {
	color: white;
	text-align: right;

	&:hover {
		color: white;
	}

	@media @mobile {
		text-align: center;
	}
}

img.HoverBounce, .HoverBounce img {
	.Transform(translateZ(0));
	.backface-visibility(hidden);
	.transition(transform 0.5s ease-in-out);
	display: block;
	overflow: hidden;

	&:hover {
		.Transform(scale(1.1));
		.transition-timing-function(cubic-bezier(0.47, 2.02, 0.31, -0.36));
		z-index: 100;
	}
}

a.HoverBounce {
	display: block;

	img {
		.Transform(translateZ(0));
		.backface-visibility(hidden);
		.transition(transform 0.5s ease-in-out);
		display: block;
		overflow: hidden;
	}

	:hover {
		.Transform(scale(1.1));
		.transition-timing-function(cubic-bezier(0.47, 2.02, 0.31, -0.36));
		z-index: 100;
	}
}

.carousel-fade {
	.carousel-inner {
		.item {
			transition-property: opacity;
		}

		.item,
		.active.left,
		.active.right {
			opacity: 0;
		}

		.active,
		.next.left,
		.prev.right {
			opacity: 1;
		}

		.next,
		.prev,
		.active.left,
		.active.right {
			left: 0;
			transform: translate3d(0, 0, 0);
		}
	}

	.carousel-control {
		z-index: 2;
	}
}
/*ANIMATIONS*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}
